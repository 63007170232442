import React, { useState } from 'react';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const App = () => {
  const [email, setEmail] = useState('');
  const [hasEmail, setHasEmail] = useState(false);

  const next = async (e) => {
    if (e.key === 'Enter' && validateEmail(email)) {
      console.log('Email: ' + email);
      setHasEmail(true);
    }
  }

  return (
    <div className="main-container">
      <h1 className="page-title">Emme Lu</h1>
      {hasEmail !== true && (
        <input
            type="text"
            className="email-address"
            placeholder="Email address..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={next}
        />
      )}
      {hasEmail === true && (
        <h3 className="email-success">Thank you for your interest!</h3>
      )}
    </div>
  );
}

export default App;